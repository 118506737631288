@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fade-in {
    animation: fade-in 0.5s ease-out;
  }
  
  .step-connector {
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  
  .step-connector.top {
    top: 0;
  }
  
  .step-connector.bottom {
    bottom: 0;
  }
  