/* General Styles */
.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

/* Fancy Button Styles */
.fancy-button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  color: white;
  background: linear-gradient(90deg, #1f6feb, #1b40e0);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  text-decoration: none; /* Ensures the link does not show underline */
  transition: all 0.4s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.fancy-button::before,
.fancy-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.4s ease;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  z-index: -1;
}

.fancy-button::after {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.4s ease;
  border-radius: 50px;
  z-index: 1;
}

.fancy-button:hover::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
}

.fancy-button:hover {
  background: linear-gradient(90deg, #1b40e0, #1f6feb);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.fancy-button:hover::after {
  width: 110%;
  height: 110%;
}

.fancy-button:focus::before {
  transition: none;
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
}

.fancy-button span {
  display: block;
  transition: all 0.4s ease;
}

.fancy-button:hover span {
  transform: scale(1.1);
}

/* Additional Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.4);
    opacity: 0;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.fancy-button::before {
  animation: pulse 1.5s infinite;
}

/* Text Animation */
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in 1s ease-out forwards;
}

.animate-fade-in {
  animation: fade-in 1.5s ease-out forwards;
}
